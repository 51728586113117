import React from 'react'
import styled from 'styled-components'
import themeColor from 'utils/themeColor'

// ===================================================
// STYLES
// ===================================================

const Container = styled('div')({
  position: 'relative',
  width: '100%',
  background: ({ background, theme }) => themeColor(background, 'gray', theme),
})

const Content = styled('div')({
  margin: ({ paddingTop, paddingBottom }) =>
    `${paddingTop || '0px'} auto ${paddingBottom || '0px'}`,
  padding: 24,
  width: '100%',
  maxWidth: 1600,
})

const Tagline = styled('span')({
  position: 'relative',
  display: 'block',
  fontSize: 21,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginBottom: 8,
  color: ({ color, theme }) => themeColor(color, 'blue', theme),
  '@media (max-width: 600px)': {
    fontSize: 14,
    wordBreak: 'break-word',
  },
})

const Heading = styled('h2')({
  position: 'relative',
  fontSize: 46,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginBottom: 8,
  color: ({ color, theme }) => themeColor(color, 'darkGray', theme),
  '@media (max-width: 600px)': {
    fontSize: 24,
    wordBreak: 'break-word',
  },
})

const Subheading = styled('h3')({
  position: 'relative',
  fontSize: 28,
  fontWeight: 'bold',
  marginBottom: 8,
  color: ({ color, theme }) => themeColor(color, 'white', theme),
  '@media (max-width: 600px)': {
    fontSize: 20,
    wordBreak: 'break-word',
  },
})

// ===================================================
// COMPONENTS
// ===================================================

const StoryblokHeadingsBlock = ({
  data: {
    background,
    tagline,
    heading,
    subheading,
    taglineColor,
    headingColor,
    subheadingColor,
    paddingTop,
    paddingBottom,
  },
}) => (
  <Container background={background}>
    <Content paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Tagline color={taglineColor}>{tagline}</Tagline>
      <Heading color={headingColor}>{heading}</Heading>
      <Subheading color={subheadingColor}>{subheading}</Subheading>
    </Content>
  </Container>
)

export default StoryblokHeadingsBlock
